import React, { useState } from 'react';
import InputField from './InputField';

const QuestionDisplay = ({ question, placeholder, onBack, onAnswerSubmit, isLastQuestion }) => {
    const [answer, setAnswer] = useState('');
    const [loading, setLoading] = useState(false);
    const [finished, setFinished] = useState(false);

    const handleChange = (e) => {
        setAnswer(e.target.value);
    };

    const handleSubmit = async () => {
        if (!answer.trim()) {
            alert('Please provide an answer.');
            return;
        }

        if (answer.length < 50) {
            alert('Your answer is too short. Please provide an answer of at least 50 characters.');
            return;
        }

        if (isLastQuestion) {
            setLoading(true);
        }

        try {
            await onAnswerSubmit(answer);
            if (isLastQuestion) {
                setLoading(false);
                setFinished(true);
            } else {
                setAnswer('');
            }
        } catch (error) {
            setLoading(false);
        }
    };


    if (loading) {
        return (
            <div className="flex flex-col justify-center items-center h-screen space-y-4">
                <div className="animate-spin w-16 h-16 border-t-4 border-purple-600 rounded-full"></div>
                <div className="text-purple-600 text-xl font-semibold">Loading... Please Wait...</div>
            </div>
        );
    }

    return (
        <div>
            <h3 className="m-5 mt-20 lg:text-5xl font-bold text-center">{question}</h3>
            <InputField
                className="border-solid border-2 border-gray-400 px-4 py-2 w-full"
                value={answer}
                onChange={handleChange}
                placeholder={placeholder}
                required
            />
            <div className="m-5 flex justify-between mb-60">
                {onBack && (
                    <button
                        className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                        onClick={onBack}
                    >
                        Back
                    </button>
                )}
                <button
                    className="px-4 py-1 text-sm text-purple-600 font-semibold rounded-full border border-purple-200 hover:text-white hover:bg-purple-600 hover:border-transparent focus:outline-none focus:ring-2 focus:ring-purple-600 focus:ring-offset-2"
                    onClick={handleSubmit}
                >
                    {isLastQuestion ? 'Finish' : 'Next'}
                </button>
            </div>
        </div>
    );
};

export default QuestionDisplay;
