import React from 'react';

// Helper function to format the key into readable text
function formatKeyToReadableText(key) {
    return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
}

const BusinessPlanDisplay = ({ answers, generatedPlan }) => {
    const { detailLevel, ...answerEntries } = answers;

    return (
        <div className="mt-10 mb-20 bg-white rounded shadow p-6">
            <h3 className="text-4xl font-bold mb-4 text-indigo-800 text-center">Business Plan</h3>
            <div className="mb-4">
                {Object.keys(answerEntries).map((key, index) => (
                    <div key={index} className="mb-4">
                        <p className="text-2xl font-semibold mb-2 text-gray-700">{formatKeyToReadableText(key)}:</p>
                        <p className="text-xl ml-4 text-gray-800">{answerEntries[key]}</p>
                    </div>
                ))}
            </div>
            {generatedPlan && typeof generatedPlan === 'object' && (
                <div className="mt-4">
                    {Object.keys(generatedPlan).map((key) => (
                        <div key={key} className="mb-4">
                            <h4 className="text-2xl font-semibold text-indigo-800 text-center mb-10">{key}</h4>
                            <p className="text-xl ml-2 text-gray-800 text-justify">{generatedPlan[key]}</p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default BusinessPlanDisplay;
