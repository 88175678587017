import React, { useState } from 'react';

const AdminDashboard = () => {
    const [users, setUsers] = useState([
        { id: 1, username: 'john_doe', email: 'john@example.com' },
        { id: 2, username: 'jane_doe', email: 'jane@example.com' },
        { id: 3, username: 'random_user', email: 'random@example.com' },
    ]);
    const [newUser, setNewUser] = useState({ username: '', email: '' });
    const [editingUser, setEditingUser] = useState(null);
    const [newUsername, setNewUsername] = useState('');
    const [newEmail, setNewEmail] = useState('');

    const handleDelete = (userId) => {
        setUsers(users.filter(user => user.id !== userId));
    };

    const handleEdit = (user) => {
        setEditingUser(user);
        setNewUsername(user.username);
        setNewEmail(user.email);
    };

    const handleSave = (userId) => {
        setUsers(users.map(user => {
            if (user.id === userId) {
                return { ...user, username: newUsername, email: newEmail };
            }
            return user;
        }));
        setEditingUser(null);
    };

    const handleAddUser = () => {
        const newId = users.length ? Math.max(users.map(u => u.id)) + 1 : 1;
        setUsers([...users, { id: newId, ...newUser }]);
        setNewUser({ username: '', email: '' });
    };

    return (
        <div className="container mx-auto p-4">
            <h1 className="text-3xl mb-4">Admin Dashboard</h1>
            <div className="mb-8">
                <input
                    placeholder="Username"
                    value={newUser.username}
                    onChange={(e) => setNewUser({ ...newUser, username: e.target.value })}
                />
                <input
                    placeholder="Email"
                    value={newUser.email}
                    onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
                />
                <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleAddUser}
                >
                    Add User
                </button>
            </div>
            <table className="min-w-full bg-white">
                <thead className="bg-gray-800 text-white">
                    <tr>
                        <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">Username</th>
                        <th className="w-1/3 text-left py-3 px-4 uppercase font-semibold text-sm">Email</th>
                        <th className="text-left py-3 px-4 uppercase font-semibold text-sm">Actions</th>
                    </tr>
                </thead>
                <tbody className="text-gray-700">
                    {users.map(user => (
                        <tr key={user.id}>
                            <td className="w-1/3 text-left py-3 px-4">
                                {editingUser?.id === user.id ? (
                                    <input value={newUsername} onChange={(e) => setNewUsername(e.target.value)} />
                                ) : (
                                    user.username
                                )}
                            </td>
                            <td className="w-1/3 text-left py-3 px-4">
                                {editingUser?.id === user.id ? (
                                    <input value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                                ) : (
                                    user.email
                                )}
                            </td>
                            <td className="text-left py-3 px-4">
                                {editingUser?.id === user.id ? (
                                    <button
                                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                        onClick={() => handleSave(user.id)}
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <>
                                        <button
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                                            onClick={() => handleDelete(user.id)}
                                        >
                                            Delete
                                        </button>
                                        <button
                                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
                                            onClick={() => handleEdit(user)}
                                        >
                                            Edit
                                        </button>
                                    </>
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default AdminDashboard;
