import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="fixed bottom-0 w-full bg-gray-800 text-white p-6">
            <div className="border-t border-gray-700 pt-6 text-center">
                {/* enable admin route */}
                {/* <span className="text-sm">© {currentYear} All Rights Reserved | <span className="text-amber-400 font-bold">AIVG</span>   Powered by AIVisionGuide | <Link to="/admin" className="text-amber-400 font-bold">
                    AIVG - Ai Vision Guide Consultancy Firm
                </Link></span> */}
                <span className="text-sm">© {currentYear} All Rights Reserved | <span className="text-amber-400 font-bold">AIVG</span>   Powered by AIVisionGuide | <span to="/admin" className="text-amber-400 font-bold">
                    AIVG - Ai Vision Guide Consultancy Firm
                </span></span>
            </div>
        </footer>
    );
};

export default Footer;
