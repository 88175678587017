import React from 'react';

const InputField = ({ value, onChange, placeholder }) => {
    return (
        <div className="flex justify-center items-center">
            <textarea
                value={value}
                onChange={onChange}
                className="my-5 mx-5 border border-solid border-gray-400 rounded-lg px-4 py-2 h-96 w-full focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent break-words text-justify"
                placeholder={placeholder}
                rows="4"
            />
        </div>
    );
};

export default InputField;
