import React from 'react';
import { useNavigate, useParams } from "react-router-dom";

const FrontPage = ({ setDetailLevel }) => {
    const navigate = useNavigate();
    const { id } = useParams();

    const handleChoice = (level) => {
        setDetailLevel(level);
        navigate(`/questions/${id}`);
    };

    return (
        <div className="bg-gray-100 h-screen pt-0 lg:pt-40 items-center justify-center p-4">
            <h1 className="text-4xl font-bold mb-8 text-center w-full px-4">
                How detailed is the information you currently have about your business idea?
            </h1>
            <div className="w-full space-y-4">
                <div className="bg-white p-6 rounded-lg shadow-lg w-full text-justify hover:bg-gray-200 cursor-pointer" onClick={() => handleChoice('basic')}>
                    Basic. I know what our mission, problem, and customer base is, but not much else.
                </div>
                <div className="bg-white p-6 rounded-lg shadow-lg w-full text-justify hover:bg-gray-200 cursor-pointer" onClick={() => handleChoice('intermediate')}>
                    Intermediate. I have thought through a lot of the details and could answer most questions.
                </div>
                {/* uncomment div if advanced level is complete */}
                {/* <div className="bg-white p-6 rounded-lg shadow-lg w-full text-justify hover:bg-gray-200 cursor-pointer" onClick={() => handleChoice('advanced')}>
                    Advanced. I can answer any question you have and have charts, graphs, and calculations prepared.
                </div> */}
                {/* Disable advanced level */}
                <div className="bg-white p-6 rounded-lg shadow-lg w-full text-justify hover:bg-gray-200 cursor-not-allowed opacity-50" onClick={(e) => e.preventDefault()}>
                    Advanced. I can answer any question you have and have charts, graphs, and calculations prepared. (Coming Soon!)
                </div>
            </div>
        </div>
    );
};

export default FrontPage;
