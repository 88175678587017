import React, { Component } from 'react';
import QuestionDisplay from './Components/QuestionDisplay';
import BusinessPlanDisplay from './Components/BusinessPlanDisplay';
import axios from 'axios'; // Import Axios for making API requests
import Header from './Components/Header';
import Footer from './Components/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import FrontPage from './Components/FrontPage';
import Login from './Components/Login';
import PrivateRoutes from './Components/PrivateRoutes';
import AdminDashboard from './Components/AdminDashboard';
import { useNavigate } from 'react-router-dom';
import { Document, Packer, Paragraph, TextRun } from 'docx';

// download functional component
function DownloadButton({ answers, generatedPlan }) {
  const handleDownload = async () => {
    const sections = [];
    const { detailLevel, ...answerEntries } = answers; // Destructure and exclude detailLevel

    // Add "Business Plan" as the heading
    sections.push(new Paragraph({
      children: [new TextRun({ text: "Business Plan", bold: true, size: 32 })],
      alignment: "center"
    }));
    sections.push(new Paragraph('\n'));  // Add a newline for spacing

    // Iterate through answerEntries and add them to the document
    Object.entries(answerEntries).forEach(([key, value]) => {
      sections.push(new Paragraph({
        children: [
          new TextRun({ text: formatKeyToReadableText(key) + ":", bold: true }),
          new TextRun('\n\n'),
          new TextRun(value)
        ]
      }));
      sections.push(new Paragraph('\n'));  // Add a newline for spacing
    });

    // Helper function to format the key into readable text
    function formatKeyToReadableText(key) {
      return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
    }
 
    // Add the remaining generatedPlan sections
    if (generatedPlan && typeof generatedPlan === 'object') {
      Object.keys(generatedPlan).forEach((key) => {
        sections.push(new Paragraph({ children: [new TextRun({ text: key, bold: true }), new TextRun('\n\n'), new TextRun(generatedPlan[key])] }));
        sections.push(new Paragraph('\n'));  // Add a newline for spacing
      });
    }

    const doc = new Document({
      sections: [{
        properties: {},
        children: sections
      }]
    });

    const blob = await Packer.toBlob(doc);

    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'BusinessPlan.docx';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <button onClick={handleDownload} className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded ml-4">
        Download Plan
      </button>
    </div>
  );
}

// ResetButton functional component
function ResetButton({ resetState }) {
  const navigate = useNavigate();

  return (
    // <div className="mt-10 mb-20 bg-white rounded shadow p-6">
    <div className="mb-20">
      <button
        onClick={() => {
          resetState();
          navigate("/start/:id");
        }}
        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-4"
      >
        Create New
      </button>
    </div>
  );
}

class App extends Component {
  state = {
    detailLevel: '',
    questions: [
      'What is your mission statement?',
      'What problem does your business solve?',
      'Who is your target customer?',
      'How do you plan to solve the problem?',
      'What makes your business unique in terms of your team, your experience, and the knowledge you bring?',
      'What is the competitive landscape?',
      'What are the key performance indicators (KPIs) you will track?',
      'What is your go-to-market strategy?',
      'What are your scaling plans?',
      'What are the potential risks and challenges, and how do you plan to mitigate them?',
      'What is your customer acquisition and retention strategy?',
      'Test1',
      'Test2',
      'Test3',
      'Test4',
      'Test5',
      'Test6',
      'Test7',
      'Test8',
      'Test9',
    ],
    placeholderValues: [
      'Think about this in terms of the unique impact your company strives to make in the world...',
      'Think about this in terms of the problems your company strives to reduce or eliminate in the world...',
      'Think about this in terms of the animals or people your company wants to help in the world...',
      'What is your solution and how do you plan to charge for it?',
      'Explain a little about yourself, who is joining you, and what you envision the team will look like in the 3-6 months',
      '[TEST] What is the competitive landscape?',
      '[TEST] What are the key performance indicators (KPIs) you will track?',
      '[TEST] What is your go-to-market strategy?',
      '[TEST] What are your scaling plans?',
      '[TEST] What are the potential risks and challenges, and how do you plan to mitigate them?',
      '[TEST] What is your customer acquisition and retention strategy?',
      'Think about this...',
      'Think about this...',
      'Think about this...',
      'Think about this...',
      'Think about this...',
      'Think about this...',
      'Think about this...',
      'Think about this...',
      'Think about this...',
    ],
    currentQuestionIndex: 0,
    generatedBusinessPlan: '',
    showBusinessPlan: false,
    loading: false,
    user: null, //set user state
    auth: { token: false }, //set user token
  };
  // Add these methods to handle user logout
  resetState = () => {
    this.setState({
      currentQuestionIndex: 0,
      answers: {},  // Reset the answers
      showBusinessPlan: false  // Set showBusinessPlan to false
    });
  }


  handleLogin = (user, token) => {
    this.setState({ user, auth: { token: true } });  // Set token to true upon login
    console.log(this.state.auth)

  };

  handleLogout = () => {
    this.setState({ user: null, auth: { token: false } });  // Set token to false upon logout
  };

  setDetailLevel = (level) => {
    this.setState({
      detailLevel: level,
      currentQuestionIndex: 0 // Resetting the currentQuestionIndex
    });
  };



  // Function to filter answers based on the chosen detail level
  filterAnswersByLevel = (allAnswers, level) => {
    const basicKeys = ['mission_statement', 'business_problem', 'target_customer'];
    const intermediateKeys = [...basicKeys, 'business_model', 'management_team'];
    const advancedKeys = [...intermediateKeys, 'competitive_landscape', 'kpi_indicators', 'market_strategy', 'scaling_plans', 'potential_risks', 'acquisiton_retention_strat', 'Test1', 'Test2', 'Test3', 'Test4', 'Test5', 'Test6', 'Test7', 'Test8', 'Test9'];

    const keysByLevel = {
      'basic': basicKeys,
      'intermediate': intermediateKeys,
      'advanced': advancedKeys,
    };

    const keys = keysByLevel[level] || [];
    return keys.reduce((acc, key) => {
      acc[key] = allAnswers[key];
      return acc;
    }, {});
  };

  handleAnswerSubmit = (answer) => {
    const { currentQuestionIndex, questions, detailLevel } = this.state;
    const questionSubset = {
      'basic': questions.slice(0, 3),
      'intermediate': questions.slice(0, 5),
      'advanced': questions.slice(0, 20),
    }[detailLevel] || [];

    const updatedAnswers = { ...this.state.answers };
    const questionKey = this.getQuestionKeyByIndex(currentQuestionIndex);
    updatedAnswers[questionKey] = answer;

    return new Promise((resolve, reject) => {
      if (currentQuestionIndex + 1 < questionSubset.length) {
        this.setState({
          currentQuestionIndex: currentQuestionIndex + 1,
          answers: updatedAnswers,
          showBusinessPlan: false,
        });
        resolve(); // Resolve the promise without any data
      } else {
        // This is the last question. Send the answers to the backend.
        this.setState({ loading: true, answers: updatedAnswers });

        const filteredAnswers = this.filterAnswersByLevel(updatedAnswers, this.state.detailLevel);
        // save object to a variable for display
        const filteredAnswersToSend = { detailLevel: this.state.detailLevel, ...filteredAnswers };
        console.log({ detailLevel: this.state.detailLevel, ...filteredAnswers });
        axios.post('/api/send_answers', { level: this.state.detailLevel, ...filteredAnswers })
          .then((response) => {
            console.log(response.data)
            const businessPlan = response.data;
            this.setState({
              loading: false,
              showBusinessPlan: true,
              generatedBusinessPlan: businessPlan,
              finalFilteredAnswers: filteredAnswersToSend
            });
            resolve(businessPlan);
          })
          .catch((error) => {
            console.error('Error:', error.message);
            this.setState({ loading: false });
            reject(error);
          });
      }
    });
  };

  handleBack = () => {
    const { currentQuestionIndex } = this.state;
    if (currentQuestionIndex > 0) {
      this.setState({
        currentQuestionIndex: currentQuestionIndex - 1,
        showBusinessPlan: false,
      });
    }
  };

  getQuestionKeyByIndex = (index) => {
    switch (index) {
      case 0:
        return 'mission_statement';
      case 1:
        return 'business_problem';
      case 2:
        return 'target_customer';
      case 3:
        return 'business_model';
      case 4:
        return 'management_team';
      case 5:
        return 'competitive_landscape';
      case 6:
        return 'kpi_indicators';
      case 7:
        return 'market_strategy';
      case 8:
        return 'scaling_plans';
      case 9:
        return 'potential_risks';
      case 10:
        return 'acquisiton_retention_strat';
      case 11:
        return 'Test1';
      case 12:
        return 'Test2';
      case 13:
        return 'Test3';
      case 14:
        return 'Test4';
      case 15:
        return 'Test5';
      case 16:
        return 'Test6';
      case 17:
        return 'Test7';
      case 18:
        return 'Test8';
      case 19:
        return 'Test9';
      default:
        return '';
    }
  };

  render() {
    const { user, currentQuestionIndex, questions, showBusinessPlan, placeholderValues, detailLevel } = this.state;

    const questionSubset = {
      'basic': questions.slice(0, 3),
      'intermediate': questions.slice(0, 5),
      'advanced': questions.slice(0, 20),
    }[detailLevel] || [];

    return (
      <Router>
        <>
          <Header user={user} onLogout={this.handleLogout} />
          <Routes>
            <Route path="/" exact element={<Login onLogin={this.handleLogin} />} />
            <Route element={<PrivateRoutes auth={this.state.auth} />}>
              <Route path="/start/:id" exact element={<FrontPage setDetailLevel={this.setDetailLevel} />} />
              <Route path="/questions/:id" exact element={
                showBusinessPlan ? (
                  <div className='mb-10'>
                    <BusinessPlanDisplay
                      answers={this.state.finalFilteredAnswers}
                      generatedPlan={this.state.generatedBusinessPlan}
                    />
                    {/* Add the DownloadButton right next to the ResetButton */}
                    <div className="flex">
                      <ResetButton resetState={this.resetState} />
                      <DownloadButton answers={this.state.finalFilteredAnswers} generatedPlan={this.state.generatedBusinessPlan} />
                    </div>
                  </div>
                ) : (
                  <QuestionDisplay
                    question={questionSubset[currentQuestionIndex]}
                    placeholder={placeholderValues[currentQuestionIndex]}
                    onAnswerSubmit={this.handleAnswerSubmit}
                    onBack={this.handleBack}
                    isLastQuestion={currentQuestionIndex === questionSubset.length - 1}
                  />
                )
              } />
              <Route path="/admin" element={<AdminDashboard />} />
            </Route>
          </Routes>
          <Footer />
        </>
      </Router>
    );
  }
}

export default App;