import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Login = ({ onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    // State to manage the password visibility
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const handleLogin = async () => {
        setAlertMessage(''); // Clear any previous alert messages
        // Hash the password using SHA-256
        // const hashedPassword = CryptoJS.SHA256(password).toString();

        try {
            // const response = await axios.post('/api/login', { username, password: hashedPassword });
            const response = await axios.post('/api/login', { username, password: password });
            console.log(response.status)
            if (response.status === 200) {
                const { user_id } = response.data;
                console.log(response.data)
                if (user_id) {
                    onLogin(user_id);
                    // Navigate to /start/:userId
                    navigate(`/start/${user_id}`);
                } else {
                    console.error('Invalid user data:', response.data);
                    setAlertMessage('Invalid user data received.');
                }
            }
            // if (response.status === 200) {
            //     const { user_id, token } = response.data;  // Assuming the server returns a token
            //     if (user_id) {
            //         onLogin(user_id, token);  // Pass token to handleLogin
            //         navigate(`/start/${user_id}`);
            //     } else {
            //         console.error('Invalid user data:', response.data);
            //     }
            // }
        } catch (error) {
            console.error('Login Error:', error);

            if (error.response && error.response.status === 401) {
                setAlertMessage('Invalid username or password.');
            } else {
                setAlertMessage('An unexpected error occurred. Please try again.');
            }
        }
    };

    // Toggle function for the password visibility
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-200">
            <div className="bg-white p-8 rounded-lg shadow-md w-96">
                {alertMessage && (
                    <div className="mb-4 bg-red-500 text-white p-2 rounded-md">
                        {alertMessage}
                    </div>
                )}
                <h1 className="text-2xl mb-4">Login</h1>

                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
                        Email (Optional)
                    </label>
                    <input
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600" htmlFor="username">Username</label>
                    <input
                        type="text"
                        id="username"
                        className="mt-1 p-2 w-full rounded-md border"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                </div>

                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-600" htmlFor="password">Password</label>
                    <div className="relative">
                        <input
                            type={isPasswordVisible ? "text" : "password"}
                            id="password"
                            className="mt-1 p-2 w-full rounded-md border"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <span
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-0 pr-3 flex items-center cursor-pointer">
                            {isPasswordVisible ? 'Hide' : 'Show'}
                        </span>
                    </div>
                </div>

                <button
                    className="bg-blue-500 text-white p-2 rounded-md w-full"
                    onClick={handleLogin}
                >
                    Login
                </button>
            </div>
        </div>
    );
};

export default Login;
