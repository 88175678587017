import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import logo from "../Assets/Logo.png";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Header = ({ user, onLogout }) => {
    const [isNavOpen, setIsNavOpen] = useState(false);
    const navigate = useNavigate();

    const toggleNav = () => {
        setIsNavOpen(!isNavOpen);
    };

    const handleLogout = () => {
        axios.get('/api/logout')  // Update this URL to your actual logout API
            .then((response) => {
                if (response.status === 200) {
                    onLogout();  // Update the user state in App.js
                    navigate('/');  // Redirect to root URL
                }
            })
            .catch((error) => {
                console.error('Logout Error:', error);
            });
    };

    return (
        <header className="bg-gray-800 text-white p-4 flex justify-between items-center transition-all duration-300 sticky top-0 z-50">
            <div className="flex items-center">
                {/* burger nav
                <button className="md:hidden mr-4" onClick={toggleNav}>
                    <svg
                        className="h-6 w-6 fill-current text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                    >
                        <path d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 6h16v2H4zm0 5h16v2H4zm0 5h16v2H4z" />
                    </svg>
                </button> */}

                {/* logo */}
                <button onClick={() => navigate(`/start/${user}`)} className="flex items-center">
                    <img src={logo} alt="Logo" className="h-10 w-10" />
                    <p className='text-white ml-4 text-lg font-semibold tracking-wider'>
                        AI Vision Guide
                    </p>
                </button>
            </div>
            {/* <nav className={`text-center ${isNavOpen ? 'block text-left' : 'hidden'} md:flex md:items-center`}>
                <Link to="/" className="text-white hover:text-gray-400 px-3 py-2 block md:inline-block md:mt-0">Home</Link>
                <Link to="/about" className="text-white hover:text-gray-400 px-3 py-2 block md:inline-block md:mt-0">About</Link>
                <Link to="/services" className="text-white hover:text-gray-400 px-3 py-2 block md:inline-block md:mt-0">Services</Link>
                <Link to="/contact" className="text-white hover:text-gray-400 px-3 py-2 block md:inline-block md:mt-0">Contact</Link>
            </nav> */}
            {user ? (
                <button onClick={handleLogout} className="text-white hover:text-gray-400 px-3 py-2">Logout</button>
            ) : (
                <Link to="/" className="text-white hover:text-gray-400 px-3 py-2">Login</Link>
            )}
        </header>
    );
};

export default Header;
